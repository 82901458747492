<template>
  <b-container>
    <section class="d-flex justify-content-center">
      <section class="shopper-info-box">
        <h2 class="title jd-font-bold jd-text-20 ">Checkout</h2>
        <h6>
          We offer daily box delivery service for $35. Our delivery route is from 11 am - 3 pm. Monday - Friday.
          Typically we will give you a 30 minutes heads up call prior delivery.
        </h6>

        <section class="d-flex justify-content-center">
          <b-form class="flex-grow-1 mt-4" @submit.prevent="submit">
            <b-form-group class="mb-2 position-relative">
              <b-input type="email"
                       placeholder="Email"
                       :class="`jd-input ${veeErrors.has('email') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="'required|email|max:80'"
                       v-model="form.email"
                       data-vv-as="email"
                       name="email"></b-input>
              <span v-if="veeErrors.has('email')" class="text-danger jd-text-10 position-absolute">
                                                {{ veeErrors.first('email') }}
                                            </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="First & last name"
                       :class="`jd-input ${veeErrors.has('full_name') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="'required|max:200'"
                       v-model="form.full_name"
                       data-vv-as="first & last name"
                       name="full_name"
              />
              <span v-if="veeErrors.has('full_name')"
                    class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('full_name') }}
                          </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="Phone"
                       :class="`jd-input ${veeErrors.has('phone') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="{required : true, max: 14, min: 14}"
                       v-model="form.phone"
                       maxlength="14"
                       @input="acceptNumber"
                       name="phone"></b-input>
              <span v-if="veeErrors.has('phone')" class="text-danger jd-text-10 position-absolute">
                                                {{ veeErrors.first('phone') }}
                                            </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="Delivery street address"
                       :class="`jd-input ${veeErrors.has('delivery_address') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="'required|max:200'"
                       v-model="form.delivery_address"
                       data-vv-as="delivery address"
                       name="delivery_address"
              />
              <span v-if="veeErrors.has('delivery_address')"
                    class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('delivery_address') }}
                          </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="City, State, Zip"
                       :class="`jd-input ${veeErrors.has('address_city') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="{required : true}"
                       v-model="form.address_city"
                       name="address_city"></b-input>
              <span v-if="veeErrors.has('address_city')" class="text-danger jd-text-10 position-absolute">
                                                {{ veeErrors.first('address_city') }}
                                            </span>
            </b-form-group>

            <div class="d-flex justify-content-between total-box mt-3 no-shadow">

              <b-form-group class="mb-0 position-relative">
                <i class="fas fa-calendar-alt position-absolute calendar-icon"></i>
                <datepicker v-validate="'required'"
                            data-vv-name="delivery_date"
                            placeholder="Delivery date"
                            format="MMM dd yyyy"
                            :disabled-dates="disabledFn"
                            class="hover-show-tooltip is-invalid calendar-checkout"
                            v-model="form.delivery_date"
                            :input-class="`jd-input form-control  pr-0 bg-input  pl-25__input ${veeErrors.has('delivery_date') ? 'is-invalid' : ''}`"></datepicker>
                <span v-if="veeErrors.has('delivery_date')" class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('delivery_date') }}
                        </span>
              </b-form-group>

              <button class="btn btn-submit jd-font-bold text-nowrap" type="submit">Place order</button>
            </div>
          </b-form>
        </section>
      </section>
    </section>
  </b-container>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import {mapActions} from "vuex";

export default {
  components: {Datepicker},
  data() {
    return {
      form: {
        email: null,
        full_name: null,
        phone: null,
        delivery_address: null,
        address_city: null,
        delivery_date: null,
      },
      disabledFn: {
        to: this.$moment().toDate(),
        days: [6, 0],
      }
    }
  },
  methods: {
    ...mapActions(['GET_PICKUP_LOCATIONS']),
    acceptNumber() {
      let x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    submit() {
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.$emit('valid', this.form);
        }
      })
    }
  },
  created() {
    this.GET_PICKUP_LOCATIONS();
  }
}
</script>

<style scoped>

</style>
