<template>
  <div class="mb-5">
    <b-container v-if="CART_ITEMS.length" id="cart">
      <section class="d-flex justify-content-center">
        <section class="shopping--cart-box">
          <h2 class="title jd-font-bold jd-text-45 jd-text-30__mobile">Shopping cart</h2>
          <div class="d-flex justify-content-end items">
            <table>
              <tr>
                <th class="jd-font-medium text-right">Item</th>
                <th class="jd-font-medium">Qt.</th>
                <th class="jd-font-medium">Price</th>
                <th></th>
                <th></th>
              </tr>

              <tr v-for="(CART_ITEM, key) in CART_ITEMS" :key="key">
                <td class="jd-font-regular text-right">{{ CART_ITEM.name }}</td>
                <td class="jd-font-regular text-center">
                  <span v-if="editId !== CART_ITEM.id">{{ CART_ITEM.count }}</span>
                  <input type="number" v-else v-model="editQt" class="input-edit"/>
                </td>
                <td class="jd-font-regular">${{ CART_ITEM.price }}</td>
                <td>
                  <i class="fa fa-trash-alt c-pointer" @click="REMOVE_CART_ITEM(CART_ITEM.id)"></i>
                </td>
                <td>
                  <i class="fa fa-pencil-alt c-pointer" @click="editCartQt(CART_ITEM.id, CART_ITEM.count)" v-if="editId !== CART_ITEM.id"></i>
                  <i class="fa fa-check c-pointer" @click="editQtSave(CART_ITEM.id)" v-else></i>
                </td>
              </tr>

              <tr class="taxes">
                <td class="jd-font-regular text-right">SALES TAX</td>
                <td></td>
                <td>${{ cartTotal.tax }}</td>
                <td></td>
                <td></td>
              </tr>
              <tr class="taxes deliver">
                <td class="jd-font-regular text-right">DELIVERY</td>
                <td></td>
                <td>{{ !form.type ? 'Free' : '$35' }}</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>

          <div class="checkbox--custom">
            <span>Pickup (Free)</span>
            <b-form-checkbox v-model="form.type" name="check-button" switch></b-form-checkbox>
            <span>Delivery ($35)</span>
          </div>

          <div class="d-flex justify-content-end total-box">
            <h2 class="text-right jd-font-bold">Total {{ cartTotal.label }}</h2>
          </div>
        </section>
      </section>

      <div class="arrow-down">
        <img src="/img/icons/arrow_right_grey.svg" alt=""/>
      </div>
    </b-container>

    <template v-if="form.type">
      <DeliveryForm @valid="submit"/>
    </template>

    <template v-if="!form.type">
      <PickupForm @valid="submit"/>
    </template>

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Datepicker from "vuejs-datepicker";
import PickupForm from "@/views/_partials/Checkup/PickupForm";
import DeliveryForm from "@/views/_partials/Checkup/DeliveryForm";

export default {
  components: {DeliveryForm, PickupForm, Datepicker},
  data() {
    return {
      cart_products_qt: {},
      editId: null,
      editQt: null,
      form: {
        type: false,
      },
    }
  },
  computed: {
    ...mapGetters(["CART_ITEMS"]),
    cartQt() {
      return this.cart_products_qt;
    },
    cartTotal() {
      let total = 0;
      let tax = 9.38;
      this.CART_ITEMS.forEach((item) => {
        total += (item.price * item.count)
      });

      let taxTotal = total * tax / 100
      total += taxTotal;

      if (this.form.type) {
        total += 35;
      }

      return {
        total: total,
        label: `$${total.toFixed(2)}`,
        tax: taxTotal.toFixed(2)
      };
    }
  },
  created() {
    if (!this.CART_ITEMS.length) {
      this.$router.push({ name: 'error404' });
      return;
    }

    this.form.type = !!+this.$route.query.type;
  },
  mounted() {
    this.$root.$emit('hideLoader');
  },
  watch: {
    CART_ITEMS: function (newVal) {
      if (!newVal.length) {
        this.$router.push({ name: 'services', params: { slug: 'moving-supplies' }});
      }
    },
    "form.type": function () {
      this.$validator.reset();
      this.veeErrors.clear();
    }
  },
  methods: {
    ...mapActions(['ADD_CART_ITEM', 'SET_CART_COUNT', 'REMOVE_CART_ITEM', 'GET_STRIPE_SESSION_ID', 'REMOVE_CART_ITEMS']),
    submit(payload) {
      this.GET_STRIPE_SESSION_ID({ cart: this.CART_ITEMS, form: {...payload, type: this.form.type }}).then(({data}) => {
        parent.location.href = data.url;
        this.REMOVE_CART_ITEMS();
        this.$router.push({ name: 'services', params: { slug : 'moving-supplies' }});
      });
    },
    editCartQt(id, count) {
      this.editId = id;
      this.editQt = count;
    },
    editQtSave(id) {
      this.editId = null;
      this.SET_CART_COUNT({ id, qt: this.editQt });
    }
  },
}
</script>
<style scoped lang="scss">
.mt-14 {
  margin-top: 14px;
}

.text--styles {
  span:first-child {
    color: #4a4a4a;
  }

  span:nth-child(2) {
    color: #707070;
  }
}

.text-box {
  border-radius: 0;
  background-image: url('/img/icons/circle.svg');
  background-size: 120px;
  background-repeat: repeat-y;
  background-position-x: -58px;
  background-position-y: center;
  box-shadow: none;
  border: none;
}


.checkbox--custom {
  transform: rotate(90deg);
  position: absolute;
  left: 0;
  bottom: 50px;
  z-index: 44;
  @media screen and (max-width: 556px) {
    bottom: 117px;
    left: -25px;
  }

  &:after {
    content: '';
    width: 125px;
    position: absolute;
    height: 1px;
    background: #e8452a;
    display: block;
    transform: rotate(90deg);
    top: -64px;
    right: -18px;
  }

  > span {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: right;
    color: #8a8a8a;
    display: block;
    transform: rotate(-90deg);

    &:first-child {
      position: absolute;
      top: -75px;
      right: 15px;
      white-space: nowrap;
    }

    &:last-child {
      position: absolute;
      top: -79px;
      right: -30px;
      white-space: nowrap;
    }
  }

  > div {
    margin-right: 8px;
    margin-top: -6px;
    margin-left: 8px;
  }
}

::v-deep .checkbox--custom .custom-control {
  width: 72px;
  height: 30px;

  .custom-control-label::before {
    width: 72px;
    height: 30px;
    border-radius: 150px;
    background: #fff;
    border: solid 2px #ef4e23;
  }

  .custom-control-label::after {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #ef4e23;
    background-color: #ef4e23;
    top: calc(0.25rem + 3px);
    left: calc(-2.25rem + 3px);
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(41px);
  }
}

::v-deep .checkbox--custom .form-control {
  height: 35px;
  padding-top: 3px;
}

::v-deep .shopper-info-box {
  width: 60%;

  h2 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #8a8a8a;
  }

  h6 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

::v-deep .title {
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #e8452a;
  text-transform: uppercase;
}

.shopping--cart-box {
  position: relative;
  width: 50%;
  margin: 12px 0;
  padding: 9px 40px 45px;
  border-radius: 20px;
  background-image: url("/img/Shopping_Cart_Wite_Large.svg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left 50px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 5px 25px 45px;
    background-position: left 10px;
    background-size: 42%;
  }

  @media screen and (max-width: 776px) {
    background-size: 60%;
  }

  @media screen and (max-width: 420px) {
    background-size: 100%;
  }

  .title {
    text-align: center;
    color: #ef4e23;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  .items {
    table {
      tr, td, th {
        color: #707070;

        :not(:nth-child(1)) {
          color: rgba(0, 0, 0, 0.9);
        }

        :last-child {
          color: #707070;
        }
      }

      th {
        font-weight: 600;
        padding: 2px 6px;
        color: rgba(0, 0, 0, 0.6) !important;
        font-size: 16px;
      }

      td {
        padding: 2px 6px;
      }

      tr.taxes {
        td {
          border-top: 1px solid #707070;
        }
      }

      tr.deliver {
        td {
          border-top: none !important;
          border-bottom: 1px solid #707070;
        }
      }
    }
  }

  .total-box {
    margin-top: 20px;
    flex-direction: column;
    @media screen and (max-width: 556px) {
      justify-content: flex-end !important;
      margin-top: 85px;
      margin-left: -25px;
      h2 {
        white-space: nowrap;
      }
    }

    h2 {
      opacity: 0.8;
      font-size: 27px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: right;
      color: rgba(0, 0, 0, 0.7);
      text-transform: uppercase;
    }

    button {
      align-self: flex-end;
      border-radius: 150px;
      text-transform: uppercase;
      width: 160px;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.8px;
      text-align: center;
      color: #ef4e23;
      background: rgba(239, 78, 35, 0.15);
    }
  }
}


::v-deep button.btn-submit {
  align-self: flex-end;
  border-radius: 150px;
  text-transform: uppercase;
  width: 160px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #ef4e23;
  background: rgba(239, 78, 35, 0.15);
  &:focus, &.active {
    background: rgba(239, 78, 35, 0.5);
    color: #fff;
  }
}

.c-pointer {
  cursor: pointer;
}

.arrow-down {
  text-align: center;
  transform: rotate(90deg);
  margin-bottom: 20px;
  margin-top: 20px;

  img {
    width: 20px;
    object-fit: contain;
  }
}

::v-deep .calendar-icon {
  font-size: 23px;
}

::v-deep .calendar-checkout {

  input {
    border: none !important;
    padding-left: 33px;
    padding-top: 3px;
    margin-top: 9px;
    padding-bottom: 6px;
    box-shadow: none !important;
    outline: none !important;
  }
}

::v-deep .jd-input, #tj-datetime-input {
  padding-top: 2px;
}

::v-deep .custom-select {
  border-radius: 0;
  font-size: 12px;
  padding-top: 5px;
}

::v-deep .form-control {
  height: calc(1.5em + 0.75rem + -4px);
}

::v-deep .form-group {
  margin-bottom: 0.5rem;
}

::v-deep .vdp-datepicker__calendar {
  width: 250px !important;
  left: 0 !important;
}

.input-edit {
  border-radius: 4px;
  border:1px solid #707070;
  width: 48px;
  text-align: center;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
