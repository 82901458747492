<template>
  <b-container>
    <section class="d-flex justify-content-center">
      <section class="shopper-info-box">
        <h2 class="title jd-font-bold jd-text-20 ">Checkout</h2>
        <h6>
          We offer daily store pickup at 2 of our locations during 10 am - 3 pm. Monday - Friday. Its by appointment
          only, so please give us heads up when you are on your way.
        </h6>

        <section class="d-flex justify-content-center">
          <b-form class="flex-grow-1 mt-4" @submit.prevent="submit">
            <b-form-group class="mb-4 position-relative">
              <i class="fas fa-calendar-alt position-absolute calendar-icon"></i>
              <datepicker v-validate="'required'" data-vv-name="pickup_date" placeholder="Pickup date"
                          format="MMM dd yyyy"
                          :disabled-dates="disabledFn"
                          class="hover-show-tooltip is-invalid calendar-checkout"
                          v-model="form.pickup_date"
                          :input-class="`jd-input form-control  pr-0 bg-input w-100 pl-25__input
                            ${veeErrors.has('pickup_date') ? 'is-invalid' : ''}`"></datepicker>
              <span v-if="veeErrors.has('pickup_date')" class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('pickup_date') }}
                        </span>
            </b-form-group>

            <b-form-group class="position-relative">
              <b-form-select type="text"
                             :options="[{ text: 'Pickup Location', value: null, disabled: true}, ...PICKUP_LOCATIONS]"
                             :class="`jd-input ${veeErrors.has('pickup_location') ? 'is-invalid' : ''} hover-show-tooltip`"
                             v-validate="'required'"
                             v-model="form.pickup_location"
                             name="pickup_location"></b-form-select>
              <span v-if="veeErrors.has('pickup_location')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('pickup_location') }}
                                </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="email" placeholder="Email"
                       :class="`jd-input ${veeErrors.has('email') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="'required|email|max:80'"
                       v-model="form.email"
                       data-vv-as="email"
                       name="email"></b-input>
              <span v-if="veeErrors.has('email')" class="text-danger jd-text-10 position-absolute">
                                                {{ veeErrors.first('email') }}
                                            </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="First & last name"
                       :class="`jd-input ${veeErrors.has('full_name') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="'required|max:200'"
                       v-model="form.full_name"
                       data-vv-as="first & last name"
                       name="full_name"
              />
              <span v-if="veeErrors.has('full_name')"
                    class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('full_name') }}
                          </span>
            </b-form-group>

            <b-form-group class="mb-2 position-relative">
              <b-input type="text" placeholder="Phone"
                       :class="`jd-input ${veeErrors.has('phone') ? 'is-invalid' : ''} hover-show-tooltip`"
                       v-validate="{required : true, max: 14, min: 14}"
                       v-model="form.phone"
                       maxlength="14"
                       @input="acceptNumber"
                       name="phone"></b-input>
              <span v-if="veeErrors.has('phone')" class="text-danger jd-text-10 position-absolute">
                                                {{ veeErrors.first('phone') }}
                                            </span>
            </b-form-group>

            <div class="d-flex justify-content-end total-box mt-3 no-shadow">
              <button class="btn btn-submit jd-font-bold" type="submit">Place order</button>
            </div>
          </b-form>
        </section>
      </section>
    </section>
  </b-container>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {Datepicker},
  data() {
    return {
      form: {
        pickup_date: null,
        pickup_location: null,
        email: null,
        full_name: null,
        phone: null,
      },
      disabledFn: {
        to: this.$moment().toDate(),
        days: [6, 0],
      }
    }
  },
  computed: {
    ...mapGetters(["PICKUP_LOCATIONS"]),
  },
  methods: {
    ...mapActions(['GET_PICKUP_LOCATIONS']),
    acceptNumber() {
      let x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    submit() {
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.$emit('valid', this.form);
        }
      })
    }
  },
  created() {
    this.GET_PICKUP_LOCATIONS();
  }
}
</script>

<style scoped>

</style>
